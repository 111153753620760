
import {ColorUtils} from '@/model/project/utils/colorutils'
import { LineColorProperty } from "./properties/linecolorproperty";
import { FillColorProperty } from "./properties/fillcolorproperty";
import { TextView } from './text'
import Konva from "konva";
import { format } from 'date-fns'
import AuthModule from "@/store/auth.module";
import historydatabaserequest from '@/model/requests/historydatabaserequest';
import TagsService from '@/services/tags.service';
import trenddialogboxModule from "@/store/trenddialogbox.module";
import { Trend } from "./trend";
import { Value } from '../value';


export class TrendHistoryDB extends Trend{
    historydbname:string;
   

    public drawObject():void{
		super.initObject();

        if((this.begin==0 || this.begin==undefined) && (this.end==0 || this.end==undefined)){
            this.end = Date.now()
            this.begin = this.end - 1000*60*this.defaulttimeperiod;   
        }
        
        let dash:number[];
		switch(this.linestyle)
		{
			case "Dash": 
                dash = [6.5*this.gridlinewidth, 2*this.gridlinewidth];
				break;
			case "Dot": 
                dash = [1.7*this.gridlinewidth, 1.7*this.gridlinewidth];
				break;
			case "DashDot": 
                dash = [6.5*this.gridlinewidth, 2*this.gridlinewidth, 1.5*this.gridlinewidth, 2*this.gridlinewidth];
				break;
			default:
				break;
		}

		let color:string = this.color;
		const lineprop = this.properties['linecolor'];
		if (lineprop!=null){	
			const linecolorproperty:LineColorProperty =Object.assign(new LineColorProperty(), lineprop);
			if (linecolorproperty!=null) 
					color = linecolorproperty.getColor(this.tagnames, this.color)
			
		}	
		let fillcolor = '0xffffff00';
       
            fillcolor = this.fillcolor;
            const fillprop = this.properties['fillcolor'];
            if (fillprop!=null){	
                const fillcolorproperty:FillColorProperty =Object.assign(new FillColorProperty(), fillprop);
                if (fillcolorproperty!=null) 
                    fillcolor = fillcolorproperty.getColor(this.tagnames, this.fillcolor)   
        } 
        if (AuthModule.currentUser!=null){
           this.curves.forEach((curve)=>{
            console.log(curve.path)
            if (curve.hide) return;
            const request:historydatabaserequest = {token:AuthModule.currentUser.accessToken, 
                name:curve.path==null?curve.tagname:curve.path, begindate:this.begin, enddate:this.end, historydbname:this.historydbname};
              TagsService.gethistorydatabase(request,(data:string)=>{
                   const historydata =JSON.parse(data);    
                   curve.historydata = historydata        
                   const points = []
                   if(historydata.length>1){
                   historydata.forEach((data, index)=>{
                        if(data['x']<this.begin || data['x']>this.end)return
                    
                        if(curve.type==0){
                            const x =(data['x']-this.begin)*rectwidth/(this.end-this.begin)
                            let y = (this.maximum - data['y'])*rectheight/(this.maximum-this.minimum)
                            if(y<0) y=0
                            if(y> rectheight) y=rectheight
                            points.push(horgap+x)
                            points.push(y)
                        }else if(curve.type==1){
                            const x = (data['x']-this.begin)*rectwidth/(this.end-this.begin);
                            let y = (this.maximum-data['y'])*rectheight/(this.maximum-this.minimum);
                            if (y<0) y=0;
                            if (y>rectheight) y=rectheight;
                            if (points.length<=0){
                                points.push(horgap+x)
                                points.push(rectheight)
                            }else{
                                points.push(horgap+x)
                                points.push(y)
                            }
                        }else if(curve.type==2){
                            const previosData = historydata[index-1]
                            let oldy = index==0? 0 : (this.maximum-previosData['y'])*rectheight/(this.maximum-this.minimum);
                            const x = (data['x']-this.begin)*rectwidth/(this.end-this.begin);
                            let y = (this.maximum-data['y'])*rectheight/(this.maximum-this.minimum);
                            if (y<0) y=0;
                            if (y>rectheight) y=rectheight;
                            if (points.length<=0){
                                oldy=y}
                            if(oldy!=y){
                                points.push(horgap+x)
                                points.push(oldy)
                            }
                            points.push(horgap+x)
                            points.push(y)
                            oldy=y
                        }else if(curve.type==3){
                            const previosData = historydata[index-1]
                            let oldy = index==0? 0 : (this.maximum-previosData['y'])*rectheight/(this.maximum-this.minimum);
                            const x = (data['x']-this.begin)*rectwidth/(this.end-this.begin);
                            let y = (this.maximum-data['y'])*rectheight/(this.maximum-this.minimum);
                            if (y<0) y=0;
                            if (y>rectheight) y=rectheight;
                            if (points.length<=0){
                                oldy=y
                                points.push(horgap+x)
                                points.push(rectheight)}
                            if(oldy!=y){
                                points.push(horgap+x)
                                points.push(oldy)
                            }
                            points.push(horgap+x)
                            points.push(y)
                            oldy=y

                        }
                    })
                    
                    if(curve.type==0 || curve.type==2){
                    const curveLine = new Konva.Line({
                        points: points,
                        strokeWidth: curve.linewidth,
                        stroke: ColorUtils.convertformat(curve.color)
                    })
                    this.node.add(curveLine)
                    }else if(curve.type==1 || curve.type==3){
                        if(points.length>2){
                            points.push(points[points.length-2])
                            points.push(rectheight)
                        }
                        const curveLine = new Konva.Line({
                            points:points,
                            closed: true,
                            fill: ColorUtils.transColor(ColorUtils.convertformat(curve.color),0.3),
                            strokeWidth: curve.linewidth,
                            stroke: ColorUtils.convertformat(curve.color)

                         
                        })
                        this.node.add(curveLine)
                    }
                    const border = new Konva.Rect({
                        x: horgap,
                        y: 0,
                        width: rectwidth,
                        height: rectheight,
                        stroke: ColorUtils.convertformat(color),
                        strokeWidth: this.linewidth,
                          });
                    this.node.add(border)
                }
              }, 
              (data:string)=>{
                  console.log('data', data);
               })
            
           })
            
        } 
        
        if (this.linewidth<1 || this.linewidth==0) this.linewidth=1;
		if (this.gridlinewidth<1)this.gridlinewidth=1;
		if (this.horizontally<1)this.horizontally=1;
		if (this.vertically<1) this.vertically=1;
		const vergap = this.height/10;
		const horgap = this.width/10;
		const rectwidth=this.width-horgap;
		const rectheight=this.height-vergap;
		if (this.fill){
			const rect = new Konva.Rect({
                x: horgap,
                y: 0,
                width: rectwidth,
                height: rectheight,
                fill: ColorUtils.convertformat(fillcolor),
                strokeWidth: this.linewidth,
              });
              this.node.add(rect)
		}

        const horwidth = rectwidth/this.horizontally;
		const verwidth = rectheight/this.vertically;
		const step = (this.maximum-this.minimum)/this.vertically;

        for (let i=0;i<=this.vertically;i++){
            const line = new Konva.Line({
                points: [horgap, i*verwidth, this.width, i*verwidth],
                stroke: ColorUtils.convertformat(this.gridcolor),
                strokeWidth: this.gridlinewidth,
                dash: dash
            })
            this.node.add(line)
			
			let value = this.maximum-i*step;
            value = parseFloat(value.toFixed(2));

            const mark = new TextView()
            mark.width = this.width/10
            mark.height = this.fontsize
            mark.text = value.toString()
            mark.fontsize = this.fontsize
            mark.posx = 0
            mark.textplacement = 2
            mark.textcolor = this.markcolor
            mark.useborder = false
            mark.bordercolor = null
            mark.linewidth = this.linewidth
            mark.fill = false
            mark.fillcolor = 'white'
            mark.underline = false
            if(i==0) {mark.posy = i*verwidth}
            else if(i==this.vertically){mark.posy =i*verwidth-this.fontsize}
            else{mark.posy = i*verwidth-this.fontsize*2/3}

            mark.initLayer(this.layer)
            mark.drawObject()
            this.node.add(mark.rotatednode)
		}

		for (let j=0;j<=this.horizontally;j++){
            const line1 = new Konva.Line({
                points: [horgap+j*horwidth, 0, horgap+j*horwidth, rectheight],
                stroke: ColorUtils.convertformat(this.gridcolor),
                strokeWidth: this.gridlinewidth,
                dash: dash
            })
            this.node.add(line1)
          
           
           const timestep = (this.end-this.begin)/this.horizontally;

            const markTime = new TextView()
            markTime.width = horwidth
            markTime.height = this.fontsize
            markTime.text = format(this.begin+timestep*j, this.timeformat).toString()
            markTime.fontsize = this.fontsize
            markTime.posy = rectheight+this.fontsize/2
            markTime.textplacement = j==0?  0 : (j==this.horizontally? 2: 1)
            markTime.textcolor = this.markcolor
            markTime.useborder = false
            markTime.bordercolor = null
            markTime.linewidth = this.linewidth
            markTime.fill = false
            markTime.fillcolor = 'white'
            markTime.underline = false
            if(j==0) {markTime.posx = horgap}
            else if(j==this.horizontally){markTime.posx =this.width-horwidth}
            else{markTime.posx = horgap+(j-1)*horwidth+horwidth/2}

            markTime.initLayer(this.layer)
            markTime.drawObject()
            this.node.add(markTime.rotatednode)
		}
        this.node.on('mousedown touchstart',()=>{  
            trenddialogboxModule.setTrend(this)    
            trenddialogboxModule.setHistoryDB(this.historydbname) 
            trenddialogboxModule.setTrendDialogEnabled(true);
      });
      this.node.on('mousemove',(event)=>{
        this.clearShapesAndLabels()
         this.findPoints(event)
          this.drawTimesLinesAndTexttime(this.points)
          this.drawCurveTicks(this.points)
      })
      this.node.on('mouseleave mouseout',(event)=>{
       this.clearShapesAndLabels()
      })

        
    }

    public setField(field:string, value:Value){
        super.setField(field, value)
        switch(field){
            case "autorefresh": {
                if(value.value == 0 || value.value == '0' || value.value==false || value.value.toLowerCase()=='false'){
                    this[field]= false
                    this.rotatednode=null;
                }else if(value.value == 1 || value.value == '1' || value.value==true || value.value.toLowerCase()=='true'){
                    this[field]= true 
                    this.rotatednode=null;
                } 
                break;}
        }
        //this.drawObject();
    }

    public getField(field:string):Value{
        const value = super.getField(field)
        switch(field){
            case "autorefresh": {value.datatype=0; value.value = (this[field]);break}
        }
        return value;
    }
}