import { VuexModule,getModule, Module, Mutation, Action } from 'vuex-module-decorators';
import TagsService from '@/services/tags.service';
import store from './index'
import tag from '@/model/tag';
import writetagvaluerequest from '@/model/requests/writetagvaluerequest';
import authModule from './auth.module';
import response from '../model/response'
import cardsrequest from '@/model/requests/cardsrequest';

@Module({ name:"Tags", dynamic:true, store})
class Tags extends VuexModule {
  public tags:tag[]=[];
  public groups:string[]=[];
  public event = false;
  public eventmessage = ''
  public eventtagname = ''
  public allmessage = 'All';


@Mutation 
public addTagEvent(tag:tag){
  this.event = true;
  this.eventmessage = tag.tagvalue;
  this.eventtagname = tag.name;
}
@Mutation
public setAllMessage(msg:string){
  console.log(msg)
  this.allmessage = msg
}

@Mutation
public removeTagEvent(){
  this.event = false;
  this.eventmessage = '';
  this.eventtagname = '';
}

@Mutation
public addTagMutation(tag1:tag){
      //console.log("addTagMutation")
       const tagfind = this.tags.find(t=>t.path ===tag1.path);
       if (tagfind!=null){
        if(tag1.usevalue && !tagfind.usevalue)
          {
            tagfind.viewtype = tag1.viewtype;
            tagfind.bgcolorstr = tag1.bgcolorstr;
            tagfind.colorstr = tag1.colorstr;
            tagfind.description = tag1.description;
            tagfind.icon = tag1.icon;
            tagfind.maxvalue = tag1.maxvalue;
            tagfind.minvalue = tag1.minvalue;
            tagfind.taggroup = tag1.taggroup;
            tagfind.unit = tag1.unit;
            tagfind.useevents = tag1.useevents;
            tagfind.usehistory = tag1.usehistory;
            tagfind.usevalue = tag1.usevalue;
           // if (tagfind.decimalpos<=0)
              tagfind.decimalpos = tag1.decimalpos;
            //this.tags = this.tags.filter(t=>t.name!=tag.name)
            //this.tags.push(tag)
          }
        
        tagfind.datetime = tag1.datetime;
       // tagfind.tagvalue = tag1.tagvalue;
       if (tag1.tagvalue==null){
          tag.setValue(tagfind,'0')
          this.tags.filter(function( tag ) {
            return tag.path !== tag1.path;
        });
       }
       else{
        if (!tag1.local)
          tag.setValue(tagfind, tag1.tagvalue) 
       }
        
       // console.log('tagfind',tagfind)
       }
       else{
        //console.log("tag=",tag)
        this.tags.push(tag1);
       }
        
        this.tags = this.tags.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()))
          
}


@Mutation
public writeTagMutation(request:writetagvaluerequest){
//console.log(request)
  const tagfind:tag = this.tags.find(i => i.path === request.name);
  if (tagfind!=null)
    //tag.tagvalue = request.tagvalue;
    tag.setValue(tagfind,request.tagvalue)
   /* */
   
}
@Mutation
public addGroupMutation(group:string){
        if (this.groups.includes(group))
        return;
        this.groups.push(group);
  }
@Mutation
public clearAllTagsMutation(){
    //this.tags.splice(0);
    console.log("clear=", this.tags)
    this.tags = this.tags.filter(tag=>tag.local)
    console.log("clear2=", this.tags)
}
@Mutation
public initGroupsMutation(){
    this.groups =[this.allmessage];
}
@Action
public resetAllTagsAction(){
   this.tags.forEach(t=>{
    if (!t.local)
      t.tagvalue = '0';
    this.addTagMutation(t);
   })
   
}
@Action
public initTags():void{
  this.clearAllTagsMutation();
  this.initGroupsMutation();
}
@Action
public getAllTagsAction(token:string):void{
   // this.clearAllTagsMutation();
   // this.initGroupsMutation();
    let uuid = localStorage.getItem('uuid');
    if (uuid==null){
      uuid =Math.random().toString(36).slice(-10);
      localStorage.setItem('uuid', uuid);
    }
    TagsService.getalltags(token, uuid,(data:string)=>{
        const tag:tag = JSON.parse(data);
       /* tag.path=""
        if (tag.groupid!=null && tag.groupid!="")
          tag.path+=tag.groupid.replace(":","/")
        if (tag.edgenodeid!=null && tag.edgenodeid!=""){
          if (tag.path!="") tag.path+="/"
          tag.path+=tag.edgenodeid;
        }
        if (tag.deviceid!=null && tag.deviceid!=""){
          if (tag.path!="") tag.path+="/"
          tag.path+=tag.deviceid
        }
        if (tag.path!="") 
          tag.path+="/"
        tag.path+=tag.name*/
        console.log("GETALLTAGS TAG:",tag)
        if (tag.name.length==0){
       
         // this.clearAllTagsMutation();
          //this.initGroupsMutation();

          return;
        }
        if (tag.datetime<0){
          this.addTagEvent(tag);
          return;
        }
        if (tag.tagvalue==undefined)
          tag.tagvalue = null
        else{
              if (tag.tagvalue=='true')
                tag.tagvalue ='1'
              if (tag.tagvalue=='false')
                tag.tagvalue = '0'
              if (tag.datatype==5 || tag.datatype==6)
                tag.tagvalue = tag.tagvalue.replace(',','.')
         }
    
        this.addTagMutation(tag);
        if (tag.taggroup!=null)
         this.addGroupMutation(tag.taggroup);
    },(data:string)=>{
      console.log(data);
      this.clearAllTagsMutation();
      this.initGroupsMutation();
    });
}

@Action({rawError: true})
public writeTagAction(request:writetagvaluerequest){
  request.token = authModule.currentUser.accessToken;
  request.datetime = new Date().getTime();
 
  this.writeTagMutation(request);
  const tagfind:tag = this.tags.find(i => i.path === request.name);
  if (tagfind==null || tagfind.local) return;
    TagsService.tagupdate(request,(data:string)=>{
        this.writeTagMutation(request);
    },(data:string)=>{
      console.log(data);
    });
}
@Action({ rawError: true })
public getCardsToSaveAction(tags:tag[]):Promise<response>{
  return new Promise (()=>{ 
        tags.forEach(tag=>{
            tag.usevalue = true;
            this.addTagMutation(tag);
        })
        TagsService.getCardsToSave({token:authModule.currentUser.accessToken,
          cards: JSON.stringify(tags)},(data:string)=>{
            console.log(data)
         },(data:string)=>{
           console.log(data);
         })
  })
}
@Action({ rawError: true })
public getCardsAction():Promise<tag[]>{
  return new Promise ((resolve)=>{ 

    TagsService.getCards({token:authModule.currentUser.accessToken,
                          cards:""},(data:string)=>{
      const response:cardsrequest = JSON.parse(data);
        const cardtags:tag[] =  JSON.parse(response.cards); 
        cardtags.forEach(cardtag=>{
          console.log("tagcard=",cardtag)
          this.addTagMutation(cardtag);
          this.addGroupMutation(cardtag.taggroup)
      })  
        console.log(cardtags);
        resolve(cardtags)   
    },(data:string)=>{
      this.resetAllTagsAction();
      console.log(data);
    });
  })
}
get getTag(){return function (path:string){
  const tagfind = this.tags.find(t=>t.path ===path);
  return tagfind
}}
get getTagByName(){return function(name:string){
    const tagfind = this.tags.find(t=>t.name===name);
    return tagfind;
}}
get getAllTags():tag[] {
  
  return this.tags;
}

get getGroups():string[]{
  return this.groups;
}

get getEvent():boolean{
  return this.event;
}

get getEventMessage():string{
  return this.eventmessage;
}

get getEventTagName():string{
  return this.eventtagname;
}

/*private isNumeric(n) {
  return !isNaN(parseFloat(n)) && isFinite(n);
}*/
}

export default getModule(Tags);